import { useCallback, useMemo } from 'react'
import { useGuestUserContext } from '@/context/GuestUserContext'
import { useQueryClient } from '@tanstack/react-query'
import { useUser } from '@/services/User/hooks'
import { catNavCmsQueryOptions, cmsQueryOptions } from '@/services/CMS/queries'
import {
  getAddressParamsForCMS,
  getGuestAddressParamsForCMS,
} from '@/services/CMS/utils'
import {
  type StoreParams,
  useStoreParams,
} from '@/utils/dataFetching/storeParams'

export const useCMSParamsWithoutSlug = () => {
  const user = useUser()
  const storeParams = useStoreParams()
  const { guestAddress } = useGuestUserContext() ?? {}

  return useMemo(() => {
    return {
      storeParams,
      addressParams: user
        ? getAddressParamsForCMS(user)
        : getGuestAddressParamsForCMS(guestAddress),
    }
  }, [guestAddress, storeParams, user])
}

export const usePrefetchCmsShopQueries = () => {
  const queryClient = useQueryClient()

  const { storeParams, addressParams } = useCMSParamsWithoutSlug()

  const prefetchCMSRetailerQueries = useCallback(
    (
      params: Pick<StoreParams, 'store_id' | 'store_location_id' | 'metro_id'>
    ) => {
      queryClient.prefetchQuery(
        cmsQueryOptions({
          slug: 'shop',
          storeParams: { ...storeParams, ...params },
          addressParams,
        })
      )

      queryClient.prefetchQuery(
        catNavCmsQueryOptions({
          storeParams: { ...storeParams, ...params },
          addressParams,
        })
      )
    },
    [addressParams, queryClient, storeParams]
  )

  return { prefetchCMSRetailerQueries }
}
